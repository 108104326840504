import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { ScrollLocky } from "react-scroll-locky";
import {
  mainWhite,
  mainGray,
  mainBlue,
  mainBlack,
  graphik,
  larsseit,
  screen,
  transHover,
} from "../components/common/variables";
import AnimatedHeading from "../components/animated-heading/contact-page";
import {
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import Obfuscate from "react-obfuscate";

const Wrapper = styled.div`
  background: ${mainBlack};
  padding: 0 26px;
  @media ${screen.xsmall} {
    padding: 0 60px;
  }
  @media ${screen.small} {
    padding: 0 80px;
  }
  @media ${screen.medium} {
    padding: 0 7vw;
  }

  .contact {
    color: ${mainWhite};

    &__heading {
      font-family: ${larsseit};
      line-height: 1.23;
      font-size: 2.53rem;
      margin-bottom: 40px;
      @media ${screen.xsmall} {
        font-size: 3.3rem;
      }
      @media ${screen.small} {
        font-size: 4rem;
      }
      @media ${screen.medium} {
        font-size: clamp(1.4rem, calc(1.4rem + 3vw), 5.01rem);
      }

      @media ${screen.xlarge} {
        margin-bottom: 60px;
      }
    }

    &__subheading {
      font-family: ${graphik};
      font-size: 1.1rem;
      @media ${screen.medium} {
        font-size: 1.33rem;
      }
    }

    &__details {
      font-family: ${graphik};

      .link {
        a {
          color: ${mainGray};
          font-size: 1.1rem;
          white-space: pre-line;
          margin-top: 26px;
          display: inline-block;
          transition: ${transHover};
          @media ${screen.xsmall} {
            font-size: 1.1rem;
            margin-top: 26px;
          }
          @media ${screen.medium} {
            font-size: calc(0.3rem + 1vw);
            font-size: 1.33rem;
            margin-top: 40px;
          }

          @media ${screen.withCursor} {
            &:hover {
              color: ${mainBlue};
            }
          }
        }
      }

      .social-list {
        display: flex;
        align-items: center;
        margin-top: 40px;
        @media ${screen.xsmall} {
          margin-top: 60px;
        }

        &__social-item {
          a {
            color: ${mainGray};
            font-size: 1.95rem;
            display: block;
            margin-right: 28px;
            transition: ${transHover};
            @media ${screen.withCursor} {
              &:hover {
                color: ${mainBlue};
              }
            }
          }
        }
      }
    }
  }
`;
const ContactPage = ({ data }) => {
  // all content from prismic via graphql query at the bottom
  const { email, phone, address, social_media } = data.content.data;

  // return a string url to use for meta details
  const url = typeof window !== "undefined" && window.location.href;

  return (
    <Layout colourNavbar={mainBlack} isContactPage>
      <SEO
        title="Contact | Handsome Creative"
        description="Get in touch to see how we can help grow your business."
        webPageInfo={{
          name: "Contact Handsome Creative",
          url,
          description:
            "Get in touch to see how we can help grow your business.",
        }}
      />
      <ScrollLocky isolation={false}>
        <Wrapper>
          <div className="contact">
            <AnimatedHeading className="contact__heading" />
            <p className="contact__subheading">
              Get in touch or drop in for a chat!
            </p>
          </div>

          <div className="contact__details">
            <p className="link">
              <a href={`tel:${phone}`} aria-label="Call now">
                {phone}
              </a>
            </p>
            <p className="link">
              <Obfuscate email={email} />
            </p>
            <p className="link">
              <a
                href="https://www.google.com/maps/place/Handsome+Creative/@-33.8670148,151.207324,17z/data=!3m1!4b1!4m5!3m4!1s0x6b12ae6a80ed540f:0x6e068d6288e42f45!8m2!3d-33.8670193!4d151.2095127"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visit map location"
              >
                {address}
              </a>
            </p>

            <ul className="social-list">
              {social_media.map((item) => (
                <li className="social-list__social-item" key={item.type}>
                  <a
                    href={item.full_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.type === "Instagram" && <FaInstagram />}
                    {item.type === "Facebook" && <FaFacebookF />}
                    {item.type === "Twitter" && <FaTwitter />}
                    {item.type === "LinkedIn" && <FaLinkedinIn />}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </Wrapper>
      </ScrollLocky>
    </Layout>
  );
};

export default ContactPage;

export const dataQuery = graphql`
  {
    content: prismicGeneralDetails {
      data {
        email
        phone
        address
        social_media {
          type
          full_url
        }
      }
    }
  }
`;
